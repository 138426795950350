<template>
    <div>
        <v-container>
            <v-card class="text-sm px-4 py-4">
                <v-form ref="form" @submit.prevent="confirmPayment" >
                    <div class="row">
                        <span class="col">銀行名稱</span>
                        <span class="col-8">{{ providerInfo.bank_info }}</span>
                    </div>
                    <div class="row">
                        <span class="col">帳戶名稱</span>
                        <span class="col-8">{{ providerInfo.bank_account_name }}</span>
                    </div>
                    <div class="row">
                        <span class="col">銀行帳號</span>
                        <span class="col-8">{{ providerInfo.bank_account }}</span>
                    </div>
                    <div class="row">
                        <span class="col">銀行分行代碼</span>
                        <span class="col-8">{{ providerInfo.bank_branch_id }}</span>
                    </div>
                    <div class="row px-4 py-4">
                        <v-text-field 
                            v-model="transNo"
                            :rules="rules"
                            hide-details="auto"
                            label="匯款帳號末五碼">
                        </v-text-field>
                    </div>
                    <div class="row px-4">
                        <v-btn class="mx-auto my-4" color="primary" type="submit" block>已匯款</v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-container>
    </div>
</template>

<script>
export default {
    data: () => ({
        transNo:"",
        providerInfo: {
            bank_account: null,
            bank_account_name: null,
            bank_branch_id: null,
            bank_info: null,
        },
        rules: [
            value => !!value || '必填欄位.',
            value => (value && value.length === 5) || '請輸入末五碼',
            value => (value && !isNaN(value)) || '請輸入末五碼',
        ],
    }),
    computed: {
        providerId() {
            return this.$route.params.providerId;
        },
        storeId() {
            return this.$store.getters[`member/storeId`];
        },
        orderId() {
            return this.$route.query.orderId;
        },
    },
    methods: {
        async confirmPayment(event) {
            const validate = await this.$refs.form.validate();
            if (!validate) return;
            try{
                this.$store.dispatch("loading/active");
                const paymentLink = await this.$api.collection.orderApi.paymentByATM({
                    orderId: this.orderId,
                    transNo: this.transNo
                });
                this.$store.dispatch("loading/close");
                this.$router.push(`/provider-store/${this.providerId}/orders/${this.orderId}`)
            } catch(e) {
                console.log("[ERROR]", e)
                this.$store.dispatch("loading/close");
                this.$notify.error("發生錯誤，請稍後重試")
            }
        },
        async readApi() {
            const res = await this.$api.collection.publicApi.getProviderInfo(
                this.providerId
            );
            this.providerInfo = res;
            return res;
        },
    },
    mounted(){
        this.readApi();
        if (!this.orderId) {
            this.$router.push(`/provider-store/${this.providerId}/product/list?per=15`)
        }
    },
}
</script>

<style  scoped>

</style>